import Draggable from "react-draggable";
import { useState } from "react";
import '../components.css';
import { useNavigate } from "react-router-dom";
import Scheduler from "./Scheduler";
import { setHasProjectInfoAck, setToken } from "../../redux/features/authSlice";
import { useDispatch } from "react-redux";

function ProjectEligibilityQuestions() {
    const [checked, setChecked] = useState({ first: false, second: false });

    const dispatch = useDispatch()
    async function onContinueClick() {
        const response = await fetch("/createSchedulerToken?" + new URLSearchParams({
            handle: (Math.floor(Math.random() * 9000) + 1000).toString() + "_" + (Date.now()).toString(),
            passcode: "0000",
            survey_url: "",
            survey_text: "Ilab 2025"

        }).toString(), {
        }).then(response => response.json())
        dispatch(setToken(response.scheduler_token))
        dispatch(setHasProjectInfoAck())
    }

    const navigate = useNavigate();
    function onDeclineClick() {    
        navigate({
            pathname: '/decline',
        });
    }

    function handleCheckboxChange(event, checkbox) {
        setChecked(prev => ({ ...prev, [checkbox]: event.target.checked }));
    }

    const isProceedDisabled = !(checked.first && checked.second);

    return (
        <Draggable
            positionOffset={{ x: '-50%', y: '-50%' }}
            bounds={{ left: -850, right: 850, top: -450, bottom: 500 }}
            handle=".tabHeadingBar">
            <div className={"cursor tabDefaults windows95Tab projectEligiblity"}>
                <div className="tabHeadingBar">
                    <div className="tabHeading">Project Eligibility</div>
                </div>

                <div>
                    <label className="flex">
                        <input
                            type="checkbox"
                            className="eligibility-checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'first')}
                        />
                        <span>
                            <b className="tab-text">Are ALL of the following TRUE for you?</b>
                            <br />
                            <div className="smaller-tab-text">
                                <ul>
                                    <li>can sit for 3 hours in a single session</li>
                                    <li>are between 18-70 years old</li>
                                    <li>are familiar with cyber attack techniques</li>
                                    <li>have correctable 20/20 vision in both eyes</li>
                                    <li>understand instructions in English</li>
                                    <li>can use a keyboard and mouse</li>
                                </ul>
                            </div>
                        </span>
                    </label>
                </div>

                <div>
                    <label className="flex">
                        <input
                            type="checkbox"
                            className="eligibility-checkbox"
                            onChange={(e) => handleCheckboxChange(e, 'second')}
                        />
                        <span>
                            <b className="tab-text">Do NONE of the following apply to you?</b>
                            <br />
                            <div className="smaller-tab-text">
                                <ul>
                                    <li>Have a cognitive impairment that interferes with reading, speaking, or understanding directions in English</li>
                                    <li>Have color blindness</li>
                                    <li>Have uncorrected hearing loss</li>
                                    <li>Have a history of epilepsy triggered by visual stimuli</li>
                                    <li>Participated in a SaikoCTF before</li>
                                </ul>
                            </div>
                        </span>
                    </label>
                </div>

                <div className="centerText">
                    <div>
                        <button
                            className={`windows95Tab tabDefaultButton leftButton ${isProceedDisabled ? "grayButton" : ""}`}
                            onClick={() => onContinueClick()}
                            disabled={isProceedDisabled}>

                            PROCEED
                        </button>
                        <button
                            className="windows95Tab tabDefaultButton rightButton"
                            onClick={onDeclineClick}>
                            DECLINE
                        </button>
                    </div>
                </div>
            </div>
        </Draggable>
    );
}

export default ProjectEligibilityQuestions;
