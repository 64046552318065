import LandingPage from './components/LandingPage.jsx'
import { store } from './redux/store.js'
import { Provider } from 'react-redux'
import './App.css'
import React from "react";
import {
  HashRouter as Router,
  //BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import SurveyUrls from "./components/SurveyUrls.jsx"
import OnboardSurveyUrls from "./components/OnboardSurveyUrls";
import DeclinePageSimple from './components/DeclinePageSimple.jsx'

function App() {
  return  <Provider store={store}>
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="ilab2025" element={<LandingPage uiFlagForced="ilab2025"/>} />
        <Route path="decline" element={<DeclinePageSimple />} />
        <Route path="urls" element={<SurveyUrls />} />
        <Route path="surveys" element={<OnboardSurveyUrls />} />
        {/* <Route path="/ecsc-info" element={<InfoSite link={`${window.location.origin}/#/ecsc`}/>} />
        <Route path="/ekoparty-info" element={<InfoSite link={`${window.location.origin}/#/ekoparty`}/>} />
        <Route path="ekoparty" element={<LandingPage uiFlagForced="ekoparty"/>} />
        <Route path="survey1" element={<Survey1 />} />
        <Route path="phase2" element={<LandingPage2 />} />
        <Route path="results" element={<SurveyResults />} />
        
        <Route path="/ecsc-surveys" element={<OnboardSurveyUrls />} /> */}
      </Routes>
  </Router>
  </Provider>
}

export default App;
