import React from "react"
import './components.css'

function DeclinePageSimple() {
    
    return (
        <div style={{fontSize: "40px", margin: "50px"}}>
          Thank you for consdering SaikoCTF. If you declined by accident, feel free to reapply. Otherwise, have a nice day.
        </div>
    );
}

export default DeclinePageSimple;